<template>
  <div>
    <v-row justify="center">
      <v-col cols="6" md="auto">
        <v-radio-group v-model="tipos_reporte">
          <v-radio
            v-for="n in items_tipos_reporte"
            :key="n.valor"
            :label="n.texto"
            :value="n.valor"
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="6" md="auto" align-self="center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Filtrar por
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in items_filtros"
              :key="index"
              @click="establecerFiltro(item)"
            >
              <v-list-item-title>{{ item.nombre }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col
        cols="6"
        md="2"
        align-self="center"
        v-if="filtro_seleccionado == 'fecha'"
      >
        <v-text-field
          v-model="fecha_desde"
          label="Desde"
          type="date"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col
        cols="6"
        md="2"
        align-self="center"
        v-if="filtro_seleccionado == 'fecha'"
      >
        <v-text-field
          v-model="fecha_hasta"
          label="Hasta"
          type="date"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="4"
        align-self="center"
        v-if="filtro_seleccionado == 'asegurado'"
      >
        <v-autocomplete
          v-model="list_clientes_seleccionados"
          :items="items_clientes"
          item-text="nombre"
          item-value="id_cliente"
          label="Asegurado"
          outlined
          dense
          hide-details
          multiple
          chips
          small-chips
          deletable-chips
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="4"
        align-self="center"
        v-if="filtro_seleccionado == 'aseguradora'"
      >
        <v-autocomplete
          v-model="list_aseguradoras_seleccionadas"
          :items="items_aseguradoras"
          item-text="descripcion"
          item-value="id_aseguradoras"
          label="Aseguradoras"
          outlined
          dense
          hide-details
          multiple
          chips
          small-chips
          deletable-chips
        ></v-autocomplete>
      </v-col>
      <v-col cols="auto" align-self="center">
        <v-btn color="grey" dark @click="listarCuadros()">Descargar</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="false">
      <v-col cols="3" class="mx-auto" v-if="list_items == 0">
        <span class="title">No hay resultados</span>
      </v-col>
      <v-col cols="3" class="mx-auto" v-if="list_items != 0">
        <span class="title"
          >Se encontraron {{ list_items.length }} resultados</span
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
var XLSX = require("xlsx");
import moment from "moment";

export default {
  data: () => ({
    tipos_reporte: "polizas_iniciales",
    items_tipos_reporte: [
      { texto: "Polizas iniciales", valor: "polizas_iniciales" },
      {
        texto: "Cancelaciones y exclusiones",
        valor: "cancelaciones_exclusiones",
      },
      { texto: "Primas con comisiones", valor: "primas_comisiones" },
    ],
    fecha_desde: "",
    fecha_hasta: "",
    items_filtros: [
      { cod: "fecha", nombre: "Fechas" },
      { cod: "asegurado", nombre: "Asegurado" },
      { cod: "aseguradora", nombre: "Aseguradora" },
    ],
    filtro_seleccionado: "fecha",
    list_items: [],
    items_clientes: [],
    list_clientes_seleccionados: [],
    items_aseguradoras: [],
    list_aseguradoras_seleccionadas: [],
  }),
  created() {
    this.listarClientes();
    this.listarAseguradoras();
  },
  methods: {
    listarClientes() {
      axios.get("api/Clientes/Listar").then((response) => {
        //console.log(response.data);

        this.items_clientes = response.data;
      });
    },
    listarAseguradoras() {
      axios.get("api/Aseguradoras/Listar").then((response) => {
        //console.log(response.data);

        this.items_aseguradoras = response.data;
      });
    },
    establecerFiltro(item) {
      this.filtro_seleccionado = item.cod;
    },
    listarCuadros() {
      if (
        this.filtro_seleccionado == "fecha" &&
        !this.fecha_desde &&
        !this.fecha_hasta
      ) {
        return;
      }
      if (
        this.filtro_seleccionado == "asegurado" &&
        this.list_clientes_seleccionados == 0
      ) {
        return;
      }
      if (
        this.filtro_seleccionado == "aseguradora" &&
        this.list_aseguradoras_seleccionadas == 0
      ) {
        return;
      }

      axios
        .post("api/Polizas/ListarPorCuadros", {
          tipo_filtro: this.filtro_seleccionado,
          id_cliente: this.list_clientes_seleccionados,
          id_aseguradora: this.list_aseguradoras_seleccionadas,
          desde: this.fecha_desde == "" ? new Date() : this.fecha_desde,
          hasta: this.fecha_hasta == "" ? new Date() : this.fecha_hasta,
        })
        .then((response) => {
          //console.log(response.data);
          this.list_items = [];
          if (this.tipos_reporte == "polizas_iniciales") {
            for (var i = 0; i < response.data.length; i++) {
              if (response.data[i].inicial_renovacion == "Es poliza inicial") {
                this.list_items.push({
                  Asegurado: response.data[i].asegurado,
                  Aseguradora: response.data[i].aseguradora,
                  "Tipo de póliza": response.data[i].tipo_poliza,
                  "Número de póliza": response.data[i].num_poliza,
                  "Ejecutivo asignado": response.data[i].ejecutivo,
                  "Fecha desde": this.formatoFecha(
                    response.data[i].fecha_desde
                  ),
                  "Prima comisionable": response.data[i].prima_comisionable,
                  "% De comisión": response.data[i].porc_comision,
                  "Comisión vigencia completa":
                    response.data[i].comision_vig_compl,
                  "Numero de cuotas": response.data[i].num_cuotas,
                });
              }
            }
            this.generarExcel(this.list_items);
          } else if (this.tipos_reporte == "cancelaciones_exclusiones") {
            for (var i = 0; i < response.data.length; i++) {
              if (!response.data[i].activo) {
                this.list_items.push({
                  Asegurado: response.data[i].asegurado,
                  Aseguradora: response.data[i].aseguradora,
                  "Tipo de póliza": response.data[i].tipo_poliza,
                  "Número de póliza": response.data[i].num_poliza,
                  "Ejecutivo asignado": response.data[i].ejecutivo,
                  "Fecha de cancelación": this.formatoFecha(
                    response.data[i].fecha_cancelacion
                  ),
                  Motivo: response.data[i].motivo,
                  "Prima comisionable": response.data[i].prima_comisionable,
                  "% De comisión": response.data[i].porc_comision,
                  "Comisión vigencia completa":
                    response.data[i].comision_vig_compl,
                });
              }
            }
            this.generarExcel(this.list_items);
          } else if (this.tipos_reporte == "primas_comisiones") {
            for (var i = 0; i < response.data.length; i++) {
              this.list_items.push({
                Aseguradora: response.data[i].aseguradora,
                Cliente: response.data[i].asegurado,
                "Número de póliza": response.data[i].num_poliza,
                Ramo: response.data[i].ramo,
                "Prima neta": response.data[i].prima_neta,
                "% de comisiones": response.data[i].porc_comision,
                "Comision en $": response.data[i].comision_dolar,
              });
            }
            this.generarExcel(this.list_items);
          }
        });
    },
    generarExcel(excel_data) {
      if (excel_data.length == 0) {
        return;
      }

      let data = XLSX.utils.json_to_sheet(excel_data);
      const workbook = XLSX.utils.book_new();
      const filename = "Reporte";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    formatoFecha(fecha) {
      if (!fecha) {
        return "";
      }
      return moment(fecha).format("YYYY-MM-DD");
    },
  },
};
</script>

<style scoped>
.borde {
  border: 1px black solid;
}
</style>