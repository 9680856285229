<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      :clipped-left="$vuetify.breakpoint.mdAndUp"
    >
      <v-app-bar-nav-icon
        v-if="estaLogueado"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title
        v-if="estaLogueado && $vuetify.breakpoint.mdAndUp ? true : false"
        >Sistema corredora de seguros</v-toolbar-title
      >
      <v-btn
        outlined
        rounded
        class="mx-1"
        @click="nuevaVersion()"
        v-if="estaLogueado && $store.state.usuario.id_usuario == 1"
        >VERSION</v-btn
      >

      <v-spacer></v-spacer>

      <v-btn color="info" @click="estadoSesion()">
        <span class="mr-2">{{ btn_login }}</span>
        <v-icon v-if="estaLogueado" class="fas fa-sign-out-alt"></v-icon>
        <v-icon v-else class="fa-solid fa-right-from-bracket"></v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      app
      v-model="drawer"
      :clipped="$vuetify.breakpoint.mdAndUp"
      :temporary="$vuetify.breakpoint.smAndDown ? true : false"
      v-if="estaLogueado"
    >
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img :src="require('@/assets/logo.png')"></v-img>
        </v-list-item-avatar>

        <v-list-item-title>{{
          this.$store.state.usuario.nombre
        }}</v-list-item-title>
      </v-list-item>

      <v-divider class="mx-2"></v-divider>

      <v-list dense class="py-0">
        <v-list-item exact :to="{ name: 'bienvenida' }">
          <v-list-item-action>
            <v-icon>fas fa-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Inicio </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mx-2"></v-divider>

        <!--ACCESOS -->
        <template>
          <v-list-group color="primary">
            <v-list-item slot="activator">
              <v-list-item-content>
                <v-list-item-title> Accesos </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{ name: 'usuarios' }"
              v-if="moduloVisible('usuarios')"
            >
              <v-list-item-icon>
                <v-icon>fas fa-user</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Usuarios</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{ name: 'roles' }" v-if="moduloVisible('roles')">
              <v-list-item-icon>
                <v-icon>fas fa-user-cog</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Roles</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{ name: 'permisos' }"
              v-if="moduloVisible('permisos')"
            >
              <v-list-item-icon>
                <v-icon>fas fa-user-cog</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Permisos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
        <v-divider class="mx-2"></v-divider>

        <!-- SEGURIDAD -->
        <template v-if="false">
          <v-list-group color="primary">
            <v-list-item slot="activator">
              <v-list-item-content>
                <v-list-item-title> Seguridad </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{ name: 'cambioPass' }"
              v-if="moduloVisible('cambioPass')"
            >
              <v-list-item-icon>
                <v-icon>fa-solid fa-user-shield</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Contraseña</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>

        <!--CATALOGOS -->
        <template>
          <v-list-group color="primary">
            <v-list-item slot="activator">
              <v-list-item-content>
                <v-list-item-title> Catálogos </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{ name: 'aseguradoras' }"
              v-if="moduloVisible('aseguradoras')"
            >
              <v-list-item-action>
                <v-icon>fas fa-external-link-alt</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Aseguradoras </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{ name: 'categoriaSeguros' }"
              v-if="moduloVisible('categoriaSeguros')"
            >
              <v-list-item-action>
                <v-icon>fas fa-external-link-alt</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Categoria de seguros </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{ name: 'clientes' }"
              v-if="moduloVisible('clientes')"
            >
              <v-list-item-action>
                <v-icon>fas fa-external-link-alt</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Clientes </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{ name: 'seguros' }"
              v-if="moduloVisible('seguros')"
            >
              <v-list-item-action>
                <v-icon>fas fa-external-link-alt</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Productos </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{ name: 'planes' }"
              v-if="moduloVisible('planes')"
            >
              <v-list-item-action>
                <v-icon>fas fa-external-link-alt</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Planes </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{ name: 'ramoSeguros' }"
              v-if="moduloVisible('ramoSeguros')"
            >
              <v-list-item-action>
                <v-icon>fas fa-external-link-alt</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Ramo de seguros </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{ name: 'tipoCuotas' }"
              v-if="moduloVisible('tipoCuotas')"
            >
              <v-list-item-action>
                <v-icon>fas fa-external-link-alt</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Tipo de cuotas </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{ name: 'tipoReclamos' }"
              v-if="moduloVisible('tipoReclamos')"
            >
              <v-list-item-action>
                <v-icon>fas fa-external-link-alt</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Tipos de reclamo </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{ name: 'tipoDocumentos' }"
              v-if="moduloVisible('tipoDocumentos')"
            >
              <v-list-item-action>
                <v-icon>fas fa-external-link-alt</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Tipo de documentos </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{ name: 'tipoSeguros' }"
              v-if="moduloVisible('tipoSeguros')"
            >
              <v-list-item-action>
                <v-icon>fas fa-external-link-alt</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Tipo de seguros </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{ name: 'beneficiarioCesion' }"
              v-if="moduloVisible('beneficiarioCesion')"
            >
              <v-list-item-action>
                <v-icon>fas fa-external-link-alt</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Beneficiario cesión </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
        <v-divider class="mx-2"></v-divider>
        <!--POLIZAS -->
        <template>
          <v-list-group color="primary">
            <v-list-item slot="activator">
              <v-list-item-content>
                <v-list-item-title> Pólizas </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{ name: 'polizas', params: { id: 'all' } }"
              v-if="moduloVisible('polizas')"
            >
              <v-list-item-icon>
                <v-icon>fas fa-file-signature</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Asegurados</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
        <v-divider class="mx-2"></v-divider>
        <!--REPORTES -->
        <template>
          <v-list-group color="primary">
            <v-list-item slot="activator">
              <v-list-item-content>
                <v-list-item-title> Reportes </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{ name: 'estadoCuenta' }"
              v-if="moduloVisible('estadoCuenta')"
            >
              <v-list-item-icon>
                <v-icon>fas fa-print</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Estado de cuenta</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{ name: 'cuadrosReportes' }"
              v-if="moduloVisible('cuadrosReportes')"
            >
              <v-list-item-icon>
                <v-icon>fas fa-table</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Cuadros</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
        <v-divider class="mx-2"></v-divider>
        <!--DASHBOARD -->
        <v-list-item
          :to="{ name: 'dashboard' }"
          v-if="moduloVisible('dashboard')"
        >
          <v-list-item-action>
            <v-icon>far fa-chart-bar</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Dashboard </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- BUSQUEDA -->
        <v-list-item
          :to="{ name: 'busqueda' }"
          v-if="moduloVisible('busqueda')"
        >
          <v-list-item-action>
            <v-icon>fas fa-search</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Busqueda </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-footer app absolute padless color="primary">
      <v-row justify="end" class="ma-0">
        <v-col cols="auto" class="pa-0">
          <v-list-item>
            <v-list-item-avatar class="mx-auto">
              <v-icon color="white" small>fa-regular fa-copyright</v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="white--text">
              {{ 2023 }} — Desarrollado por Devco
            </v-list-item-content>
            <v-list-item-avatar class="mx-auto">
              <a href="https://www.devco.sv" target="_blank">
                <v-img
                  max-width="35"
                  max-height="35"
                  :src="require('@/assets/logo_devco.png')"
                >
                </v-img
              ></a>
            </v-list-item-avatar>
          </v-list-item>
        </v-col>
      </v-row>
    </v-footer>

    <v-snackbar
      centered
      v-model="snackbar"
      color="deep-purple accent-4"
      elevation="24"
      vertical
      timeout="-1"
      v-if="estaLogueado"
    >
      <span class="subtitle-1"> {{ snackbar_text }} </span>
      <v-btn color="white" icon @click="snackbar = false">
        <v-icon>far fa-times-circle</v-icon>
      </v-btn>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          outlined
          rounded
          v-bind="attrs"
          @click="actualizarPagina()"
        >
          Actualizar pagina
        </v-btn>
      </template>
    </v-snackbar>

    <notifications group="files" position="top right" />

    <v-fab-transition>
      <v-btn
        v-scroll="onScroll"
        v-show="scroll_top"
        fixed
        color="primary"
        fab
        dark
        bottom
        right
        class="mb-12"
        @click="toTop()"
      >
        <v-icon large>fas fa-caret-up</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import router from "./router";
import axios from "axios";
import inactividad from "./components/Inactividad.vue";
const signalR = require("@microsoft/signalr");

export default {
  data: () => ({
    drawer: true,
    btn_login: "Iniciar sesion",
    items_permisos: [],
    connection: "",
    snackbar: false,
    snackbar_text: "Hay una nueva versión del sistema favor de actualizar",
    scroll_top: false,
  }),
  components: {
    inactividad,
  },
  computed: {
    estaLogueado() {
      if (this.$store.state.usuario) {
        this.btn_login = "Cerrar sesion";
        this.listarPermisos();
        this.esVisibleModulo();
        return this.$store.state.usuario;
      } else {
        this.btn_login = "Iniciar sesion";
      }
    },
    estaInactivo() {
      return this.$store.state.idleVue.isIdle;
    },
  },
  beforeMount() {
    this.$store.dispatch("autoLogin");
  },
  mounted() {
    this.connection.on("Receive", (user, data) => {
      const insertdata = { name: user, message: data };

      //console.log("escuchando...", insertdata);
      this.snackbar = true;
    });
  },
  created() {
    this.connection = new signalR.HubConnectionBuilder()
      .withAutomaticReconnect()
      .withUrl(process.env.VUE_APP_URL_API + "/testHub", {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .build();

    this.connection
      .start()
      .then(() => {
        console.log("Conexión establecida");
      })
      .catch((error) => {
        //console.log(error);
      });

      //console.log(this.$store.state.usuario);
  },
  methods: {
    nuevaVersion() {
      axios.post("api/Usuarios/ListenServer", {
        nombre: "Actualizacion",
        numero: "1.0",
      });
    },
    actualizarPagina() {
      router.go();
    },
    listarPermisos() {
      axios
        .get("api/Permisos/Listar/" + this.$store.state.usuario.id_usuario)
        .then((response) => {
          this.items_permisos = response.data;
        });
    },
    esVisibleModulo() {
      var path = window.location.pathname;

      axios
        .get(
          "api/Permisos/Verificar/" +
            this.$store.state.usuario.id_usuario +
            path
        )
        .then((response) => {
          if (response.data.length == 0) {
            this.$router.push({ name: "bienvenida" }).catch(() => {});
          }
        });
    },
    moduloVisible(item) {
      var item_ = this.items_permisos.find(
        (object) => object.nombre_identificador == item
      );

      if (item_ != undefined) {
        if (item_.estado) {
          return item_.visible ? false : true;
        }
      }
    },
    estadoSesion() {
      if (this.estaLogueado) {
        this.eliminarToken();
      } else {
        router.push({ name: "inicioSesion" }).catch(() => {});
      }
    },
    eliminarToken() {
      axios
        .delete("api/Seguridades/Eliminar/" + localStorage.getItem("sesion"))
        .then(() => {
          this.$store.dispatch("salir");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.scroll_top = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>