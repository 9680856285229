<template>
  <v-container class="pa-0">
    <v-data-table
      :headers="headers"
      :items="items"
      disable-pagination
      class="elevation-3"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Permisos</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-autocomplete
            v-model="id_usuario"
            :items="items_usuarios"
            item-text="nombre"
            item-value="id_usuario"
            label="Usuarios"
            outlined
            dense
            hide-details
            @change="listarPermisos()"
          ></v-autocomplete>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark @click="listarPantallas()"> Nuevo </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.estado`]="{ item }">
        <v-chip :color="colorChip(item.estado)" dark>
          <span v-if="item.estado">Permitido</span>
          <span v-else>No permitido</span>
        </v-chip>
      </template>

      <template v-slot:[`item.action_edit`]="{ item }">
        <v-icon @click="editarItem(item)"> fas fa-edit </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" width="500" scrollable persistent>
      <v-card>
        <v-card-text>
          <v-list dense>
            <v-list-item-group>
              <template v-for="(item, index) in items_pantallas">
                <v-list-item :key="item.title">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      item.nombre_pantalla
                    }}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-checkbox v-model="item.estado" hide-details>
                    </v-checkbox>
                  </v-list-item-action>
                </v-list-item>
                <v-divider
                  v-if="index < items_pantallas.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="eliminarPermisos()">Guardar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialog()">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_editar" width="500" persistent>
      <v-card>
        <v-card-title>{{ titulo_editar }}</v-card-title>
        <v-card-actions>
          <v-btn color="primary" @click="actualizarPermiso()">Actualizar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialogEditar()">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    headers: [
      { text: "Pantalla", value: "nombre_pantalla" },
      { text: "Estado", value: "estado" },
      { text: "Editar", value: "action_edit" },
    ],
    items: [],
    items_usuarios: [],
    items_pantallas: [],
    id_permiso: 0,
    id_usuario: 0,
    id_pantalla: 0,
    estado: false,
    dialog: false,
    dialog_editar: false,
    titulo_editar: "",
  }),
  created() {
    this.listarUsuarios();
  },
  methods: {
    listarUsuarios() {
      axios.get("api/Usuarios/Listar").then((response) => {
        this.items_usuarios = response.data;
      });
    },
    listarPantallas() {      
      if (this.id_usuario == 0) {
        this.alerta("info", "Seleccione a un usuario primero.");
        return;
      }

      this.editarPermisos();
      return;
    },
    listarPermisos() {
      axios.get("api/Permisos/Listar/" + this.id_usuario).then((response) => {
        //console.log(response.data);
        this.items = response.data;
      });
    },
    eliminarPermisos() {
      axios.delete("api/Permisos/Eliminar/" + this.id_usuario).then(() => {
        this.crearPermisos();
      });
    },
    crearPermisos() {
      this.items_pantallas.forEach((element) => {
        axios.post("api/Permisos/Crear", {
          id_pantalla: element.id_pantalla,
          id_usuario: this.id_usuario,
          estado: element.estado,
        });
      });
      this.alerta("info", "Proceso terminado");
      this.cerrarDialog();
      this.listarPermisos();
    },
    editarPermisos() {
      axios.get("api/Pantallas/Listar").then((response) => {     
        //console.log(response.data);   
        response.data.forEach((pantalla) => {
          var index = this.items.findIndex((obj) => obj.id_pantalla == pantalla.id_pantalla);
          if (index != -1) {
            this.items_pantallas.push({
              id_pantalla: this.items[index].id_pantalla,
              id_usuario: this.id_usuario,
              nombre_pantalla: this.items[index].nombre_pantalla,
              estado: this.items[index].estado,
            })
          } else {
            this.items_pantallas.push({
              id_pantalla: pantalla.id_pantalla,
              id_usuario: this.id_usuario,
              nombre_pantalla: pantalla.nombre,
              estado: false
            })
          }
        });
        this.dialog = true;
      });
    },
    editarItem(item) {
      this.titulo_editar = item.estado
        ? "Remover permiso: " + item.nombre_pantalla
        : "Dar permiso: " + item.nombre_pantalla;
      this.id_permiso = item.id_permiso;
      this.id_pantalla = item.id_pantalla;
      this.id_usuario = this.id_usuario;
      this.estado = !item.estado;

      this.dialog_editar = true;
    },
    actualizarPermiso() {
      axios
        .put("api/Permisos/Actualizar", {
          id_permiso: this.id_permiso,
          id_pantalla: this.id_pantalla,
          id_usuario: this.id_usuario,
          estado: this.estado,
        })
        .then(() => {
          this.alerta("success", "Permiso actualizado");
          this.dialog_editar = false;
          this.listarPermisos();
        });
    },
    colorChip(item) {
      return item ? "green" : "red";
    },
    cerrarDialog() {
      this.dialog = false;
      this.limpiar();
    },
    limpiar() {
      this.items_pantallas = [];
    },
    cerrarDialogEditar() {
      this.dialog_editar = false;
    },
    alerta(icon, mensaje) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: icon,
        title: mensaje,
      });
    },
  },
};
</script>